import React, { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
import StationsContext from "../context/otogas/stations/stationsContext";
import spinner from "./Layout/spinner";
import userCan from "../config/user_can";
import AuthContext from "../context/auth/authContext";

function Dashboard() {
  const stationsContext = useContext(StationsContext);
  const { dashboard_data, getDashboardData } = stationsContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userCan(user, "GET:Sales:GetDashBoardData")) {
      setLoading(false);
      return;
    }

    setLoading(true);
    getDashboardData().then(() => setLoading(false));
  }, [user]);

  if (loading) {
    return spinner();
  }

  if (!dashboard_data) {
    return <div>No data available</div>;
  }

  const kpiChange = (current, previous) => {
    const change = current - previous;
    const percentageChange = ((change / previous) * 100).toFixed(2);
    return {
      change: change > 0 ? `+${percentageChange}%` : `${percentageChange}%`,
      isPositive: change > 0
    };
  };

  const salesChange = kpiChange(dashboard_data.thisWeekSoldQuantities, dashboard_data.lastWeekSoldQuantities);

  // Sales Quantities Chart
  const salesSeries = [
    {
      name: "Sales Quantities (L)",
      data: [dashboard_data.thisWeekSoldQuantities || 0, dashboard_data.lastWeekSoldQuantities || 0],
    },
  ];

  const salesOptions = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      text: "Sales Quantities Comparison",
      style: {
        color: "#333",
      },
    },
    xaxis: {
      categories: ["This Week", "Last Week"],
    },
    colors: ["#007BFF"], // Primary blue
  };

  // Top Performing Stations Chart
  const topStationsData = dashboard_data.topPerformingStations || [];
  const topStationsSeries = [
    {
      name: "Quantity Sold (L)",
      data: topStationsData.map((station) => station.quantitySold || 0),
    },
  ];

  const topStationsOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 6,
      },
    },
    title: {
      text: "Top Performing Stations",
      style: {
        color: "#333",
      },
    },
    xaxis: {
      categories: topStationsData.map((station) => station.stationName || "Unknown"),
    },
    colors: topStationsData.map((_, index) => 
      ["#FF6347", "#28B463", "#1E90FF", "#F39C12", "#9B59B6", "#34495E", "#E74C3C", "#8E44AD", "#2ECC71", "#3498DB"][index % 10]
    ),
  };

  return (
    <Container fluid>
      <h2 className="my-4 text-center fw-bold">🚀 Real-Time Dashboard</h2>

      <Row className="g-4">
        <Col md={2}>
          <Card className="text-center shadow-lg border-0 h-100 d-flex" style={{ background: "linear-gradient(45deg, #007BFF, #1E90FF)", color: "white" }}>
            <Card.Body className="d-flex flex-column justify-content-center">
              <i className="fas fa-users fa-2x mb-2"></i>
              <Card.Title>Total Customers</Card.Title>
              <Card.Text className="fs-2 fw-bold">{dashboard_data.totalCustomers}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="text-center shadow-lg border-0 h-100 d-flex" style={{ background: "linear-gradient(45deg, #28B463, #2ECC71)", color: "white" }}>
            <Card.Body className="d-flex flex-column justify-content-center">
              <i className="fas fa-car fa-2x mb-2"></i>
              <Card.Title>Total Vehicles</Card.Title>
              <Card.Text className="fs-2 fw-bold">{dashboard_data.totalVehicles}</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="text-center shadow-lg border-0 h-100 d-flex" style={{ background: "linear-gradient(45deg, #FF6347, #FF9471)", color: "white" }}>
            <Card.Body className="d-flex flex-column justify-content-center">
              <i className="fas fa-chart-line fa-2x mb-2"></i>
              <Card.Title>Sales Today</Card.Title>
              <Card.Text className="fs-2 fw-bold">{dashboard_data.salesToday} L</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="text-center shadow-lg border-0 h-100 d-flex" style={{ background: "linear-gradient(45deg, #546E7A, #6C757D)", color: "white" }}>
            <Card.Body className="d-flex flex-column justify-content-center">
              <i className={`fas fa-arrow-${salesChange.isPositive ? 'up' : 'down'} fa-2x mb-2`}></i>
              <Card.Title>Sales This Week</Card.Title>
              <Card.Text className="fs-2 fw-bold">{dashboard_data.thisWeekSoldQuantities} L</Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={2}>
          <Card className="text-center shadow-lg border-0 h-100 d-flex" style={{ background: "linear-gradient(45deg, #8B008B, #BA55D3)", color: "white" }}>
            <Card.Body className="d-flex flex-column justify-content-center">
              <i className="fas fa-truck fa-2x mb-2"></i>
              <Card.Title>Vehicles Served Today</Card.Title>
              <Card.Text className="fs-2 fw-bold">{dashboard_data.totalServedVehiclesToday}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="my-4">
        <Col md={6}>
          <Card className="shadow-lg">
            <Card.Body>
              <ReactApexChart
                options={salesOptions}
                series={salesSeries}
                type="line"
                height={350}
              />
            </Card.Body>
          </Card>
        </Col>

        <Col md={6}>
          <Card className="shadow-lg">
            <Card.Body>
              <ReactApexChart
                options={topStationsOptions}
                series={topStationsSeries}
                type="bar"
                height={350}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
