import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import Pagination from "../../components/Layout/Pagination";
import StationsContext from "../../context/otogas/stations/stationsContext";
import { download_messages, messageBalance } from "../../api/otogas";
import { Modal, Button, Form, Spinner } from "react-bootstrap";

const BulkSmsSender = () => {
    const [organization, setOrganization] = useState("");
    const [balance, setBalance] = useState(null);
    const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [messageLength, setMessageLength] = useState(0);
    const [messagesCount, setMessagesCount] = useState(1);
    const [error, setError] = useState(false);
    const [step, setStep] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [batchNumber, setBatchNumber] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isDownloading, setIsDownloading] = useState(false); // Loader for download button
    const textareaRef = useRef(null);
    const MAX_CHARACTERS = 640;
    const CHARACTERS_PER_MESSAGE = 160;

    const stationsContext = useContext(StationsContext);
    const {
        sms_sender_names,
        getSenderNames,
        sendBulkMessages,
        listMessages,
        bulk_smses,
        totalMessagePages,
    } = stationsContext;

    useEffect(() => {
        getSenderNames();
    }, []);

    useEffect(() => {
        if (organization) {
            setIsLoadingData(true);
            listMessages(currentPage, itemsPerPage, organization).finally(() => setIsLoadingData(false));
        }
    }, [currentPage, organization]);

    useEffect(() => {
        setMessageLength(message.length);
        setMessagesCount(Math.ceil(message.length / CHARACTERS_PER_MESSAGE));
        setError(message.length > MAX_CHARACTERS);

        if (textareaRef.current) {
            adjustTextareaHeight();
        }
    }, [message]);

    const adjustTextareaHeight = () => {
        const textarea = textareaRef.current;
        textarea.style.height = "50px";
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleOrganizationChange = async (e) => {
        const selectedOrg = e.target.value;
        setOrganization(selectedOrg);

        if (selectedOrg) {
            setIsLoadingData(true);
            await fetchBalance(selectedOrg);
            setIsLoadingData(false);
        } else {
            setBalance(null);
        }
    };

    const fetchBalance = async (org) => {
        setIsRefreshingBalance(true);
        try {
            const { balance: orgBalance } = await messageBalance(org);
            setBalance(orgBalance);
        } catch (error) {
            console.error("Failed to fetch balance:", error);
            setBalance(null);
        } finally {
            setIsRefreshingBalance(false);
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!message || !file || error) {
            alert("Please enter a valid message, upload a file, and ensure the character limit is not exceeded.");
            return;
        }

        const formData = new FormData();
        formData.append("phoneNumber", file);
        formData.append("message", message);
        formData.append("sender", organization);

        setIsLoading(true);

        try {
            await sendBulkMessages(message, organization, formData);
            alert("Bulk SMS sent successfully.");
            setMessage("");
            setFile(null);
        } catch (error) {
            alert("Failed to send Bulk SMS. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadSubmit = async () => {


        setIsDownloading(true);
        try {
            const res = await download_messages(organization, batchNumber, startDate, endDate);


            setShowDownloadModal(false);
            listMessages(currentPage, itemsPerPage, organization); // Refresh messages
        } catch (error) {
            console.error(error);
            alert("Failed to download messages. Please try again.");
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="bulk-sms-sender">
            <Header />
            <Sidebar />
            <Main>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-10 col-sm-12">
                            <h2 className="text-center">Bulk SMS Sender</h2>

                            {step === 1 && (
                                <>
                                    <div className="form-group">
                                        <label>Select Organization:</label>
                                        <select
                                            value={organization}
                                            onChange={handleOrganizationChange}
                                            className="form-control"
                                        >
                                            <option value="">--Select Organization--</option>
                                            {sms_sender_names.map((org) => (
                                                <option key={org} value={org}>
                                                    {org}
                                                </option>
                                            ))}
                                        </select>
                                        {balance !== null && (
                                            <p className="mt-2">
                                                Credit Balance:{" "}
                                                {balance.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                            </p>
                                        )}
                                    </div>
                                    <button
                                        className="btn btn-primary mt-3"
                                        onClick={() => {
                                            if (organization) {
                                                setStep(2);
                                            } else {
                                                alert("Please select an organization to proceed.");
                                            }
                                        }}
                                        disabled={!organization || isLoadingData}
                                    >
                                        {isLoadingData ? "Loading..." : "Next Step"}
                                    </button>
                                </>
                            )}

                            {step === 2 && (
                                <>
                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="card-title">Messages for {organization}</h3>
                                            <div className="d-flex justify-content-between mb-3">
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={() => setStep(1)}
                                                >
                                                    Back
                                                </button>
                                                <div className="d-flex">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={() => setStep(3)}
                                                    >
                                                        Send SMS
                                                    </button>
                                                    <button
                                                        className="btn btn-secondary ms-2"
                                                        onClick={() => setShowDownloadModal(true)}
                                                    >
                                                        Download Messages
                                                    </button>
                                                </div>
                                            </div>

                                            {bulk_smses.length > 0 ? (
                                                <>
                                                    <table className="table table-hover table-bordered">
                                                        <thead className="table-dark">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Batch Number</th>
                                                                <th>Recipient</th>
                                                                <th>Message</th>
                                                                <th>Status</th>
                                                                <th>Cost</th>
                                                                <th>Timestamp</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bulk_smses.map((msg, index) => (
                                                                <tr key={msg.messageId}>
                                                                    <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                                                                    <td>{msg.batchNumber}</td>
                                                                    <td>{msg.recipientNumber}</td>
                                                                    <td>{msg.message}</td>
                                                                    <td>
                                                                        <span
                                                                            className={`badge ${msg.deliveryStatus === "Success"
                                                                                ? "bg-success"
                                                                                : msg.deliveryStatus === "Failed"
                                                                                    ? "bg-danger"
                                                                                    : "bg-warning"
                                                                                }`}
                                                                        >
                                                                            {msg.deliveryStatus || "Pending"}
                                                                        </span>
                                                                    </td>
                                                                    <td>{msg.cost}</td>
                                                                    <td>{new Date(msg.timestamp).toLocaleString()}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <Pagination
                                                        currentPage={currentPage}
                                                        totalPages={totalMessagePages}
                                                        setCurrentPage={setCurrentPage}
                                                    />
                                                </>
                                            ) : (
                                                <p className="text-center mt-3">No messages to display.</p>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            {step === 3 && (
                                <>
                                    <h3 className="text-center">Send Bulk SMS for {organization}</h3>
                                    <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => fetchBalance(organization)}
                                            disabled={!organization || isRefreshingBalance}
                                        >
                                            {isRefreshingBalance ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                `Credit Balance: ${balance !== null ? balance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "N/A"}`
                                            )}
                                        </button>
                                    <form onSubmit={handleSubmit} className="mt-3">
                                        <div className="form-group">
                                            <label>Message:</label>
                                            <textarea
                                                ref={textareaRef}
                                                value={message}
                                                onChange={handleMessageChange}
                                                placeholder="Enter your message"
                                                className={`form-control ${error ? "border-danger" : ""}`}
                                                maxLength={MAX_CHARACTERS}
                                                style={{ overflow: "hidden", resize: "none" }}
                                            ></textarea>
                                            <small className={`text-muted ${error ? "text-danger" : ""}`}>
                                                {messageLength} / {MAX_CHARACTERS} characters | {messagesCount} message{messagesCount > 1 ? "s" : ""}
                                                {error && " - Limit exceeded!"}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label>Phone Numbers File:</label>
                                            <input
                                                type="file"
                                                accept=".xlsx"
                                                onChange={handleFileChange}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button
                                                className="btn btn-secondary mt-3"
                                                onClick={() => setStep(2)}
                                                type="button"
                                            >
                                                Back
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-success mt-3"
                                                disabled={isLoading}
                                            >
                                                {isLoading ? "Sending..." : "Send Bulk SMS"}
                                            </button>
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {/* Download Modal */}
                <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Download Messages</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Batch Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Batch Number"
                                    value={batchNumber}
                                    onChange={(e) => setBatchNumber(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDownloadModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleDownloadSubmit} disabled={isDownloading}>
                            {isDownloading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    Downloading...
                                </>
                            ) : (
                                "Download"
                            )}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Main>
        </div>
    );
};

export default BulkSmsSender;
