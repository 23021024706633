import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import CustomersContext from '../../context/otogas/customers/customersContext';

const UpdateCustomerModal = ({ customer, show, handleClose }) => {
    const [formData, setFormData] = useState({
        customerName: '',
        customerPhone: '',
        customerEmail: '',
        identificationNumber: '',
        kraPin: '',
    });

    const customersContext = useContext(CustomersContext);
    const { updateCustomer } = customersContext;

    // Use useEffect to update form data when customer is available
    useEffect(() => {
        if (customer) {
            setFormData({
                customerName: customer.customerName || '',
                customerPhone: customer.customerPhone || '',
                customerEmail: customer.customerEmail || '',
                identificationNumber: customer.identificationNumber || '',
                kraPin: customer.kraPin || '',
            });
        }
    }, [customer]); // Run this effect when the customer prop changes

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (customer) {
            updateCustomer(customer.customerCode, formData);
        }
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Update Customer</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <Form.Group controlId="formCustomerName">
                        <Form.Label>Customer Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="customerName"
                            value={formData.customerName}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formCustomerPhone">
                        <Form.Label>Customer Phone</Form.Label>
                        <Form.Control
                            type="text"
                            name="customerPhone"
                            value={formData.customerPhone}
                            onChange={handleChange}
                            // required
                        />
                    </Form.Group>
                    <Form.Group controlId="formCustomerEmail">
                        <Form.Label>Customer Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="customerEmail"
                            value={formData.customerEmail}
                            onChange={handleChange}
                            // required
                        />
                    </Form.Group>
                    <Form.Group controlId="formIdentificationNumber">
                        <Form.Label>Identification Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="identificationNumber"
                            value={formData.identificationNumber}
                            onChange={handleChange}
                            // required
                        />
                    </Form.Group>
                    <Form.Group controlId="formKraPin">
                        <Form.Label>KRA Pin</Form.Label>
                        <Form.Control
                            type="text"
                            name="kraPin"
                            value={formData.kraPin}
                            onChange={handleChange}
                            // required
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary">
                        Update Customer
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UpdateCustomerModal;
