import React, { useEffect, useContext, useState } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import { Modal, Button, Form } from "react-bootstrap";
import CustomersContext from "../../context/otogas/customers/customersContext";
import SweetAlertWrapper from "../../components/Layout/sweetAlert";
import { download_customer_balances } from "../../api/otogas";
import { formatCurrency } from "../../api/util";
// import { upload_sales_data } from "../../api/otogas";
import FileUpload from "../../components/util/uploader";
import { download_cutomer_wallet_topup_history } from "../../api/otogas";
import DownloadButton from '../../components/util/downloader';
import Pagination from "../../components/Layout/Pagination";
import VehicleTopupModal from "./topupCustomer";
import SetCreditLimitModal from "./setCreditLimit";

const ListCustomerBalances = () => {
    const customersContext = useContext(CustomersContext);
    const {
        customer_balances,
        notification,
        clear_notification,
        allCustomerBalances,
        topUpCustomerBalance,
        uploadSalesData,
        wallets_total_records
    } = customersContext;

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // You can adjust the number of items per page as needed
    const [showModal, setShowModal] = useState(false);
    const [vehicleRegistrationNumber, setVehicleRegistrationNumber] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [showTopupModal, setShowTopupModal] = useState(false);
    const [showSetCreditLimitModal, setShowSetCreditLimitModal] =
        useState(false);
    const [vehicle, setVehicle] = useState(null);

    const [selectedCustomerBalance, setSelectedCustomerBalance] =
        useState(null);
    const [topUpAmount, setTopUpAmount] = useState("");
    const [paymentReference, setPaymentReference] = useState("");
    const [downloading, setDownloading] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });

    useEffect(() => {
        allCustomerBalances(
            vehicleRegistrationNumber,
            customerName,
            currentPage,
            itemsPerPage

        );
    }, [allCustomerBalances]);

    useEffect(() => {
        if (notification) {
            console.log(notification);
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notification();
    };

    const handleDownloadBalances = async () => {
        try {
            setDownloading(true);
            await download_customer_balances();
            setDownloading(false);
        } catch (error) {
            console.error("Error downloading customer balances", error);
            setDownloading(false);
        }
    };

    const handleCloseTopupModal = () => setShowTopupModal(false);
    const handleCloseSetCreditLimitModal = () =>
        setShowSetCreditLimitModal(false);

    const handleConfirmAlert = () => {
        setShowAlert(false);
        clear_notification();

        allCustomerBalances(
            vehicleRegistrationNumber,
            customerName,
            currentPage,
            itemsPerPage
        );


    };

    const handle_downloads = async (func, setLoading) => {
        setLoading(true);
        try {
            const res = await func();
            if (res === "File not found") {
                setAlertMessage({
                    text: "No transactions found",
                    type: "info",
                });
                setShowAlert(true);
            } else {
                // Handle file download logic here
                return res;
            }
        } catch (error) {
            console.error("Error downloading the file:", error);
            setAlertMessage({
                text: "An error occurred while downloading the file",
                type: "danger",
            });
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    };
    const handleSearchKeyPress = (e) => {
        if (e.key === "Enter") {
            setCurrentPage(1); // Reset to first page on new search
            allCustomerBalances(
                vehicleRegistrationNumber,
                customerName,
                currentPage,
                itemsPerPage
            );
        }
    };


    const handleTopUp = (customer_balance) => {
        setSelectedCustomerBalance(customer_balance);
        setShowModal(true); // Show the modal
    };

    const handleTopUpSubmit = async () => {
        if (topUpAmount && parseFloat(topUpAmount) > 0 && paymentReference) {
            try {
                console.log(selectedCustomerBalance);
                await topUpCustomerBalance({
                    vehicleCode: selectedCustomerBalance.vehicleCode,
                    amount: parseFloat(topUpAmount),
                    paymentReference,
                });
                setShowModal(false); // Hide the modal after successful top-up
            } catch (error) {
                console.error("Error topping up balance", error);
            }
        }
    };

    // Pagination logic

    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />

                {showTopupModal && (
                    <VehicleTopupModal
                        show={showTopupModal}
                        handleClose={handleCloseTopupModal}
                        vehicle={vehicle}
                    />
                )}

                {showSetCreditLimitModal && (
                    <SetCreditLimitModal
                        show={showSetCreditLimitModal}
                        handleClose={handleCloseSetCreditLimitModal}
                        vehicle={vehicle}
                    />
                )}

                <h2>Wallet Balances</h2>

                <div className="row mb-3">
                    <div className="col-md-8">
                    </div>
                    <div className="col-md-4 d-flex justify-content-start">
                        <button
                            className="btn btn-success m-1"
                            onClick={handleDownloadBalances}
                            disabled={downloading}
                        >
                            {downloading ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Downloading...
                                </>
                            ) : (
                                "Download Balances"
                            )}
                        </button>

                        <FileUpload
                            uploadFunction={uploadSalesData}
                            name="Upload balances"
                            allowed_file_types={[".xlsx", ".xls"]}
                        />

                        <DownloadButton
                            className="btn btn-secondary btn-group-sm"
                            existingFunction={download_cutomer_wallet_topup_history}
                            buttonName="Export Topup History"
                            reportType="range"
                        />
                    </div>
                </div>


                {/* Search Form */}
                <form className="mb-3">
                    <div className="row">
                        <div className="col-md-6">
                            <input
                                type="text"
                                className="form-control"
                                value={customerName}
                                onChange={(e) =>
                                    setCustomerName(e.target.value)
                                }
                                onKeyPress={handleSearchKeyPress} // Apply search on Enter
                                placeholder="Search by Name"
                            />
                        </div>
                        <div className="col-md-6">
                            <input
                                type="text"
                                className="form-control"
                                value={vehicleRegistrationNumber}
                                onChange={(e) =>
                                    setVehicleRegistrationNumber(e.target.value)
                                }
                                onKeyPress={handleSearchKeyPress} // Apply search on Enter
                                placeholder="Search by Vehicle Registration Number"
                            />
                        </div>
                    </div>
                </form>

                <table className="table">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Balance Amount</th>
                            <th>Registration Number</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customer_balances.map((balance) => (
                            <tr key={balance.customerId}>
                                <td>{balance.customerName}</td>
                                <td>{formatCurrency(balance.balance)}</td>
                                <td>{balance.registrationNumber}</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-custom-actions dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Actions
                                        </button>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <li>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() =>
                                                        handleTopUp(balance)
                                                    }
                                                >
                                                    Top-up Balance
                                                </button>
                                            </li>

                                            <li>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                        setVehicle({
                                                            vehicleCode: balance.vehicleCode,
                                                            vehicleRegistrationNumber: balance.registrationNumber,
                                                            customerName: balance.customerName
                                                        })
                                                        setShowSetCreditLimitModal(true)
                                                    }
                                                    }>
                                                    Set Credit Limit
                                                </button>
                                            </li>


                                            <li>
                                                {/* <button className="dropdown-item" onClick={() => handleTopUp(balance)}>
                          View History
                        </button> */}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Pagination */}
                {wallets_total_records > 1 && (
                    <Pagination
                        currentPage={currentPage}
                        totalPages={Math.ceil(wallets_total_records / itemsPerPage)}
                        setCurrentPage={setCurrentPage}
                    />
                )}

                {/* Modal for Top-up */}
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Top-up Balance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Top-up Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    value={topUpAmount}
                                    onChange={(e) =>
                                        setTopUpAmount(e.target.value)
                                    }
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Payment Reference</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter payment reference"
                                    value={paymentReference}
                                    onChange={(e) =>
                                        setPaymentReference(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleTopUpSubmit}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Main>
        </>
    );
};

export default ListCustomerBalances;
