import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button } from "react-bootstrap";
import StationsContext from "../../context/otogas/stations/stationsContext";
import CustomerContext from "../../context/otogas/customers/customersContext";

const UpdateProductPriceForm = ({ stationCode, show, handleClose }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isScheduled, setIsScheduled] = useState(false);

  const stationsContext = useContext(StationsContext);
  const customersContext = useContext(CustomerContext);
  const { setStationPrice, schedulePriceChange } = stationsContext;
  const { products, listProducts } = customersContext;

  useEffect(() => {
    listProducts();
  }, []);

  const onSubmit = (data) => {
    if (isScheduled) {
      // Schedule price change
      const payload = [
        {
          stationCodes: stationCode,
          product: data.productCode,
          newPrice: parseFloat(data.newPrice),
          startTime: data.startTime,
          endTime: data.endTime,
        },
      ];
      schedulePriceChange(payload);
    } else {
      // Immediate price change
      const payload = [
        {
          productCode: data.productCode,
          newPrice: parseFloat(data.newPrice),
          stationCode: stationCode,
        },
      ];
      setStationPrice(payload);
    }

    handleClose(); // Close the modal after submission
  };

  const productCodeOptions = products.map((product) => ({
    value: product.productCode,
    label: product.productName,
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Product Price</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Product</label>
            <select
              {...register("productCode", {
                required: "Product Code is required",
              })}
              className={`form-control ${errors.productCode ? "is-invalid" : ""}`}
            >
              <option value="">Select Product</option>
              {productCodeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {errors.productCode && (
              <div className="text-danger mt-1">
                {errors.productCode.message}
              </div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="newPrice">New Price</label>
            <input
              id="newPrice"
              type="number"
              step="0.01"
              className={`form-control ${errors.newPrice ? "is-invalid" : ""}`}
              {...register("newPrice", {
                required: "New price is required",
                min: {
                  value: 0,
                  message: "Price must be greater than or equal to 0",
                },
              })}
            />
            {errors.newPrice && (
              <div className="invalid-feedback">{errors.newPrice.message}</div>
            )}
          </div>

          {/* Toggle for Immediate or Scheduled */}
          <div className="form-group">
            <label>Price Change Type</label>
            <div>
              <input
                type="radio"
                id="immediate"
                value="immediate"
                checked={!isScheduled}
                onChange={() => setIsScheduled(false)}
              />
              <label htmlFor="immediate" className="ml-2">Immediate</label>
            </div>
            <div>
              <input
                type="radio"
                id="scheduled"
                value="scheduled"
                checked={isScheduled}
                onChange={() => setIsScheduled(true)}
              />
              <label htmlFor="scheduled" className="ml-2">Scheduled</label>
            </div>
          </div>

          {/* Schedule Fields */}
          {isScheduled && (
            <>
              <div className="form-group">
                <label htmlFor="startTime">Start Time</label>
                <input
                  id="startTime"
                  type="datetime-local"
                  className={`form-control ${errors.startTime ? "is-invalid" : ""}`}
                  {...register("startTime", {
                    required: "Start time is required",
                  })}
                />
                {errors.startTime && (
                  <div className="invalid-feedback">{errors.startTime.message}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="endTime">End Time</label>
                <input
                  id="endTime"
                  type="datetime-local"
                  className={`form-control ${errors.endTime ? "is-invalid" : ""}`}
                  {...register("endTime", {
                    required: "End time is required",
                  })}
                />
                {errors.endTime && (
                  <div className="invalid-feedback">{errors.endTime.message}</div>
                )}
              </div>
            </>
          )}

          <button type="submit" className="btn btn-primary mt-3">
            Submit
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProductPriceForm;
