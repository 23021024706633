import React, { useContext } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CustomerContext from "../../context/otogas/customers/customersContext";

const SetDiscountModal = ({ show, handleClose, vehicle }) => {
    const customerContext = useContext(CustomerContext);
    const { setDiscount } = customerContext;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            vehicleCode: vehicle.vehicleRegistrationNumber, // Pre-fill the vehicle code with vehicleRegistrationNumber
        },
    });

    console.log(vehicle);

    const onSubmit = (data) => {
        // Use window.confirm for a simple confirmation dialog
        const isConfirmed = window.confirm(
            `Are you sure you want to set discount for the vehicle with registration number ${vehicle.vehicleRegistrationNumber}?`,
        );

        if (isConfirmed) {
            console.log(data);
            setDiscount({
                ...data,
                vehicleCode: vehicle.vehicleCode,
            }); // Perform the top-up
            handleClose(); // Close the modal after submitting
        } else {
            alert("Vehicle top-up cancelled."); // Show a cancellation alert
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Set Discount </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {/* Vehicle Code (Non-editable) */}
                    <Form.Group controlId="vehicleCode">
                        <Form.Label>Vehicle</Form.Label>
                        <Form.Control
                            type="text"
                            value={vehicle.vehicleRegistrationNumber}
                            readOnly
                            {...register("vehicleCode")}
                        />
                    </Form.Group>

                    {/* Amount */}
                    <Form.Group controlId="amount" className="mt-3">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Enter credit limit"
                            {...register("discount", {
                                required: "Discount value is required",
                                min: 1,
                            })}
                            isInvalid={errors.creditLimit}
                        />
                        {errors.creditLimit && (
                            <Form.Control.Feedback type="invalid">
                                {errors.creditLimit.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>

                    {/* Submit Button */}
                    <Button variant="primary" type="submit" className="mt-4">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SetDiscountModal;
