import React, { useState, useContext, useEffect } from 'react';
import { Button, Row, Col, Card, Form } from 'react-bootstrap';
import Select from 'react-select';  // Import react-select
import TransactionFormModal from './addMpesaCode';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Main from '../../components/Layout/Main';
import CustomersContext from '../../context/otogas/customers/customersContext';
import SweetAlertWrapper from '../../components/Layout/sweetAlert';
import AuthContext from '../../context/auth/authContext';
import StationsContext from '../../context/otogas/stations/stationsContext';
import Pagination from '../../components/Layout/Pagination';
import Spinner from '../../components/Layout/spinner';  // Import your spinner
import MpesaCodeUsage from '../sales/paymentCodeUsage';

const ListMpesaSales = () => {
    const customersContext = useContext(CustomersContext);
    const { mpesa_payments, getMpesaPayments, notification, clear_notification, activateMpesaCode, deactivateMpesaCode } = customersContext;
    const stationsContext = useContext(StationsContext);
    const { tills, listTills } = stationsContext; // Fetch tills
    const authContext = useContext(AuthContext);
    const { user } = authContext;

    const [showAddCodeModal, setShowAddCodeModal] = useState(false);
    const [selectedTill, setSelectedTill] = useState({ value: '', label: 'All' });
    const [showAlert, setShowAlert] = useState(false); // Define showAlert state
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });
    const [startDate, setStartDate] = useState(''); // State for start date
    const [endDate, setEndDate] = useState(''); // State for end date
    const [transId, setTransId] = useState(''); // State for transaction ID
    const [loading, setLoading] = useState(false); // Loading state for spinner
    const [showMpesaUsageModal, setShowMpesaUsageModal] = useState(false);
    const [selectedTransId, setSelectedTransId] = useState(null);
    const [confirmationData, setConfirmationData] = useState({ show: false, transaction: null });


    console.log(selectedTill)
    // Function to get the current date and the date 3 months ago
    const calculateDefaultDateRange = () => {
        const currentDate = new Date();
        const one_week_ago = new Date();
        one_week_ago.setDate(currentDate.getDate() - 1);
        // const threeMonthsAgo = new Date();
        // threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

        const formattedCurrentDate = currentDate.toISOString().split('T')[0];
        const formattedOneWeekAgo = one_week_ago.toISOString().split('T')[0];

        return { formattedCurrentDate, formattedOneWeekAgo };
    };

    // implement pagination for the mpesa payments
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10);
    const totalItems = mpesa_payments.length;
    const totalPages = Math.ceil(totalItems / postsPerPage);

    // Effect to fetch tills and set a default till on component mount
    useEffect(() => {
        listTills(); // Fetch tills on component mount
    }, []);

    useEffect(() => {
        if (tills.length > 0 && !selectedTill) {
            const defaultTill = {
                value: tills[0].tillNumber,
                label: tills[0].tillName || tills[0].tillNumber,
            };
            setSelectedTill(defaultTill);
        }
    }, [tills, selectedTill]);

    useEffect(() => {
        if (selectedTill) {
            const { formattedCurrentDate, formattedOneWeekAgo } = calculateDefaultDateRange();
            setStartDate(formattedOneWeekAgo);
            setEndDate(formattedCurrentDate);

            // Fetch payments automatically with the default till and date range
            fetchPayments(selectedTill.value, formattedOneWeekAgo, formattedCurrentDate, transId);
        }
    }, [selectedTill]);

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true); // Show alert when notification is received
        }
    }, [notification]);

    // Function to handle fetching payments based on filters
    const fetchPayments = (till, start, end, transId) => {
        setLoading(true); // Set loading to true while fetching
        if (transId) {
            getMpesaPayments('', '', '', transId)
                .finally(() => setLoading(false)); // Set loading to false after fetching completes
        } else {
            getMpesaPayments(till, start, end, transId)
                .finally(() => setLoading(false)); // Set loading to false after fetching completes
        }

    };

    const handleAddTransaction = () => {
        setShowAddCodeModal(true);
    };

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notification();
        getMpesaPayments(selectedTill.value, startDate, endDate, transId);
    };

    const handleCloseModals = () => {
        setShowAddCodeModal(false);
        setShowMpesaUsageModal(false);
    };

    // Map tills for the react-select dropdown
    const tillOptions = [
        { value: '', label: 'All' },
        ...tills.map(till => ({
            value: till.tillNumber,
            label: till.tillName || till.tillNumber
        }))
    ];

    // const changeStatus = (transaction) => {
    //     if (transaction.status === 'Blocked') {
    //         activateMpesaCode(transaction.transID);
    //     }
    //     else {
    //         deactivateMpesaCode(transaction.transID);
    //     }
    // };

    const handleChangeStatus = (transaction) => {
        setConfirmationData({
            show: true,
            transaction,
        });
    };

    const confirmChangeStatus = () => {
        if (confirmationData.transaction) {
            const { transaction } = confirmationData;
            if (transaction.status === 'Blocked') {
                activateMpesaCode(transaction.transID);
            } else {
                deactivateMpesaCode(transaction.transID);
            }
        }
        setConfirmationData({ show: false, transaction: null });
    };

    const cancelChangeStatus = () => {
        setConfirmationData({ show: false, transaction: null });
    };



    return (
        <>
            <Header />
            <Sidebar />
            <Main>
                <TransactionFormModal
                    show={showAddCodeModal}
                    handleClose={handleCloseModals}
                />

                <MpesaCodeUsage
                    show={showMpesaUsageModal}
                    handleClose={handleCloseModals}
                    transId={selectedTransId}
                />

                <SweetAlertWrapper
                    show={showAlert} // Use showAlert state for SweetAlertWrapper
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type || "info"}
                />

                <SweetAlertWrapper
                    show={confirmationData.show}
                    title={`Confirm ${confirmationData.transaction?.status === 'Blocked' ? 'Activation' : 'Deactivation'}`}
                    message={`Are you sure you want to ${confirmationData.transaction?.status === 'Blocked' ? 'activate' : 'deactivate'} this transaction?`}
                    onConfirm={confirmChangeStatus}
                    onCancel={cancelChangeStatus}
                    confirmBtnText="Yes"
                    cancelBtnText="No"
                    type="warning"
                />

                <h2>Mpesa Payments</h2>

                <Row className="mb-3">
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Mpesa Transactions</Card.Title>
                                <Card.Text>
                                    Manage Mpesa transactions and codes.
                                </Card.Text>
                                <Button variant="primary" className="mb-2" onClick={handleAddTransaction}>
                                    Add Mpesa Transaction
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col md={6}>
                        <Select
                            options={tillOptions}
                            placeholder="Select a Till"
                            value={selectedTill}
                            onChange={setSelectedTill}
                        />
                    </Col>
                </Row>

                {/* Date Filters */}
                <Row className="mb-3">
                    <Col md={3}>
                        <Form.Group controlId="startDate">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="endDate">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group controlId="transId">
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Transaction ID"
                                value={transId}
                                onChange={(e) => setTransId(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={3} className="d-flex align-items-end">
                        <Button variant="primary" onClick={() => fetchPayments(selectedTill.value, startDate, endDate, transId)}>
                            Filter
                        </Button>
                    </Col>
                </Row>

                {/* Show spinner while loading */}
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Transaction ID</th>
                                    <th scope="col">Till</th>
                                    <th scope="col">Short Code</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col">Transaction Amount</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mpesa_payments.length > 0 ? mpesa_payments.slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage).map((payment, index) => (
                                    <tr key={index}>
                                        <td>{payment.transID}</td>
                                        <td>{payment.till}</td>
                                        <td>{payment.businessShortCode}</td>
                                        <td>{payment.usageBalance}</td>
                                        <td>{payment.TransAmount}</td>
                                        <td>{payment.dateTimeStamp}</td>
                                        <td>{payment.status}</td>
                                        <td>
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-secondary dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Actions
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <li>
                                                    <button 
                                                className="btn btn-link"
                                                onClick={() => handleChangeStatus(payment)}
                                            >
                                                {payment.status === 'Blocked' ? 'Activate' : 'Deactivate'}
                                            </button>
                                                    </li>

                                                    <li>
                                                        <button
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                                setSelectedTransId(payment.transID);
                                                                setShowMpesaUsageModal(true);
                                                            }}
                                                        >
                                                            Show Usage
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="6">No Mpesa Payments</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {totalPages > 1 && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={postsPerPage}
                                totalItems={totalItems}
                            />
                        )}
                    </>
                )}
            </Main>
        </>
    );
};

export default ListMpesaSales;
